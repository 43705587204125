html {
  height: 100%;
  width: 100%;
}

body {
  background-color: #222;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: inherit;
}

#root {
  height: inherit;
  display: flex;
  .app-wrapper {
    flex: 1;
    align-self:center;
  }
}

iframe {
  width: 100%;
  max-width: 100% !important;
  flex: 1 1 auto;
}

.container .chat-wrapper.hidden {
    display: none;
}

@media (max-width: 820px) {
  #root {
    align-self: baseline;
    flex: 2 2 auto;
  }
  .container { 
    flex-direction: column;
    flex: wrap;
    justify-content:center;
    .iframe-wrapper {
      position: relative;
      width: 100vw;
    }
    .chat-wrapper {
      margin: 0 auto;
      height:256px;
      max-height: 33vh;
    }
  }
}

@media (min-width: 821px){
  .app-wrapper {
    padding: 10px 2%;
  }
  .container {
    overflow:hidden;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    .iframe-wrapper {
      align-self: center;
    }
    .stream-wrapper {
      order: 1;
      flex: 5 auto;
    }
    .chat-wrapper {
      order: 2;
      max-width:300px;
      display: flex;
      flex: 2 1 auto;
      #chat-embed {
        flex: 1 1 auto;
      }
    }
  }
}

.aspect-ratio {
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  min-height:100%;
  padding-bottom: 50%;
  max-height:100%;

  #player-embed {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin-top: 2px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 40px;
  height: 19px;
  padding: 0;
  border-radius: 10px;
  background-color: #4D4D4D;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}

.react-toggle-track-check {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 5px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 8px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 5px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 0;
  left: 1px;
  width: 20px;
  height: 20px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 20px;
  border-color: #19AB27;
}